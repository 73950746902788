/* complex css */

.themeAlfa .rodimus-complex-front > header {
  padding: 0.625rem 0.938rem;
}

/* solo group */
.solo-group
  brick-teaser-v16:has([data-theme='alfa']):has(
    [data-breaking-news='true']
  ):has(breaking-text)
  .title_container
  .title {
  --brick-teaser-solo-cqi: 1.3cqi;
}

@media screen and (min-width: 533px) {
  .themeAlfa .rodimus-complex-front > header {
    padding: 0.938rem;
  }

  .themeAlfa .rodimus-complex-front > header h2 {
    font-family: var(--brick-fonts-groupTitleL);
    letter-spacing: var(--brick-letterSpacings-groupTitleL);
    font-size: var(--brick-fontSizes-groupTitleL);
    font-weight: var(--brick-fontWeights-groupTitleL);
    line-height: var(--brick-lineHeights-groupTitleL);
  }
}

@media screen and (max-width: 532px) {
  .themeAlfa .rodimus-complex-front {
    margin: 0;
    padding: 0;
    margin-bottom: var(--brick-space-deckYGapL);
    margin-top: var(--brick-space-deckYGapL);
  }

  .themeAlfa .rodimus-complex-front .rodimus-front {
    padding: var(--brick-space-none);
  }

  .themeAlfa .rodimus-complex-front > header h2 {
    margin: 0;
    padding: 0;
    font-family: var(--brick-fonts-groupTitleM);
    letter-spacing: var(--brick-letterSpacings-groupTitleM);
    font-size: var(--brick-fontSizes-groupTitleM);
    font-weight: var(--brick-fontWeights-groupTitleM);
    line-height: var(--brick-lineHeights-groupTitleM);
  }
}
